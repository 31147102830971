.load-management-dashboard-container {
  display: flex;
  width: 100%;
  min-height: 500px;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  background-color: #25272D;
  gap: 10px;
}

 .bar-chart {
    width: 31%;
  }

  .line-chart {
    position: relative;
    width: 69%;
    padding-top: 18px;

    canvas {
      max-width: 99%;
    }

    .loadingTextWithBG {
      background: #25272D;
      color: white;
      font-size: 2em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.05s ease-out;
      opacity: 0;
      visibility: hidden;
    
      &.loading {
        opacity: 1;
        visibility: visible;
      }
    }
  }

.loadManagementCurrentValues-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  font-family: Poppins, sans-serif;
  text-align: center;

  .label {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.72px;
    color: #C6C8D2;
  }
  .value {
    font-size: 58px;
    font-weight: 600;
    line-height: 60px;
  }
  .value-a {
    font-size: 25px;
    font-weight: 400;
    margin-left: 2px;
    letter-spacing: 0.6px;
  }
  .value-minus {
    font-size: 34px;
    font-weight: 600;
  }
  @media (max-width: 723px) and (min-width: 660px) {
    .label {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.72px;
      color: #C6C8D2;
    }
    .value-a {
      font-size: 20px;
    }
    .value{
      font-size: 33px;
    }
  }
  @media (max-width: 1019px) and (min-width: 724px) {
    .value{
      font-size: 34px;
    }
    .value-a {
      font-size: 20px;
    }
    .label {
      font-size: 15px;
    }  
  }
  @media (max-width: 576px) {
    .label {
      font-size: 1.2em;
      font-weight: 300;
      letter-spacing: 0.72px;
      color: #C6C8D2;
    }
    .value-a {
      font-size: 20px;
    }
    .value{
      font-size: 3em;
    }
  }
}

.houseFuse {
  color: #3998F9;
}

.phase1 {
  color: #FF9E22;
}

.phase2 {
  color: #00F6E6;
}

.phase3 {
  color: #99BD68;
}

.red {
  color: #F53920;
}

.chart-controls-wrapper {
  padding: 30px 20px;
  background-color: #25272D;
}
