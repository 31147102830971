.cloud-status-message {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #ffffff;
  border: 1px solid #5e6774;
  background-color: #2b2d34;
  resize: none;
  overflow: none;

  &::placeholder {
    color: #FFFFFF7F;
  }
}
.modal-body {
  padding-top: 25px !important;
}

.modal-footer {
  border-top: 1px solid #40424d !important;
  padding-top: 25px !important;
  margin-top: 10px !important;
}
