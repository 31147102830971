.phone-label {
    font-size: 0.8rem;
    color: #f4b530 !important;
    margin-bottom: 0.3rem;
    font-weight: 400;
    letter-spacing: 0.39px;
}

.PhoneInputInput {
    border: none;
}

.PhoneInputCountryIcon {
    color: #fff;
}

.PhoneInput {
    position: relative;
}

.PhoneInput::before {
    position: absolute;
    bottom: -2%;
    width: 100%;
    content: "";
    border-bottom: 1px solid #5e6774 !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.PhoneInput::after {
    position: absolute;
    bottom: -2%;
    width: 100%;
    content: "";
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.PhoneInput--focus::after {
    border-bottom: 1px solid #f4b530 !important;
    width: 100%;
    transform: scaleX(1);
}

.PhoneInputCountrySelectArrow {
    color: #fff;
}

.PhoneInputCountrySelect {
    font-family: "Poppins", sans-serif;
}

.PhoneInputCountrySelect option:last-child {
    display: none;
}

.PhoneInputCountrySelect::-webkit-scrollbar {
    width: 0.4em;
}

.PhoneInputCountrySelect::-webkit-scrollbar-thumb {
    background-color: #f4b530;
    outline: 1px solid #f4b530;
}
