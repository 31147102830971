.switch-tooltip + .callbackUrlField {
  input.inputField {
    padding-right: 51px;
  }
}
    
.customTextField {
  i.la.la-copy {
    position: absolute;
    right: 25px;
    bottom: 5px;
    color: rgb(244, 181, 48);
    cursor: pointer;
    font-size: 22px;
  }
}
