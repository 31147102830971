.topbar-inner-container {
  overflow-y: hidden;
}

.topbar-inner-container-row {
  white-space: nowrap;
  min-height: 62px;
}

.cloud-status-icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.cloud-ico {
  fill: #898b96;
}

.cloud-ico-ellipse {
  fill: #ff1c3c;
}

.m-btn--pill:hover{
  .cloud-ico {
    fill: #fff;
  }
}