.input-field.ip-field-container {
    margin-bottom: 50px !important;

    .ip-field {
        width: 100%;
    }

    .identify-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 20px;

        .identify-btn {
            margin-bottom: 20px !important;
            min-width: 109px;
            height: 23px;
            line-height: 0.1 !important;
        }
    }
}

