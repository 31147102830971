@import "../../source";

.circle-chart-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
  border-radius: 4px;
  gap: 5px;
  justify-content: center;

  @include set-responsive-property(5px, 5px, 10px, gap);

  canvas {
    max-height: 20vh !important;
    width: 100% !important;

    @media (min-width: 1534px) {
      max-height: 25vh !important;
    }

    @media (min-width: 1760px) {
      max-height: 30vh !important;
    }
  }

  .title {
    @include set-responsive-property(300, 400, 400, font-weight);
    @include adaptive-value(15, 27);
    font-weight: 300;
    color: $title-light-color;
    text-align: center;
    white-space: nowrap;
  }

  .chart-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;

    @include set-responsive-property(0px, 0px, 5px, gap);

    .chart-info-row {
      width: 60%;
      display: flex;
      justify-content: space-between;

      .label {
        color: $title-light-color;
        font-weight: 400;
        @include adaptive-value(12, 18);
      }

      .value {
        font-weight: 500;
        @include adaptive-value(14, 18);
      }

      .month {
        color: $status-connected-color;
      }

      .year {
        color: $status-installation-ongoing-color;
      }

      .overall {
        color: $status-not-connected-color;
      }
    }
  }
}