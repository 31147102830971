.creatingNewUserNote {
    max-width: 55rem;

    .modal-title {
        margin-left: 0px !important;
        padding-left: 40px !important;
    }

    .modal-body {
        margin-bottom: 20px !important;
    }

    .modal-footer {
        border-top: 1px solid #40424D !important;
    }
}

.creating-new-user-note-warning-icon {
    margin-right: 20px;
}