.support-contracts-container {
  margin-top: 30px;
}

.support-contracts-container label {
  display: flex;
  align-items: center;
  line-height: 19px;
}

.support-contracts {
  font-size: 16px !important;
  font-weight: 500;
  color: #8f939b !important;
}

.support-contracts-info-text {
  display: block;
  padding-left: 30px;
  margin-bottom: 10px !important;
  color: #8f939b;
  font-size: 10px;
}

.support-contracts-margin > div > label {
  margin: 0 !important;
}

.support-contracts-container label.support-contracts span {
  background-color: #40424d;
  border: none !important;
}

.support-contracts input[type="checkbox"]:checked + span {
  background-color: var(--warning);
}

.support-contracts input[type="checkbox"]:disabled + span {
  background-color: #40424d !important;
  border: 1px solid #40424d !important;
}

.support-contracts-container label.support-contracts span::after {
  border-color: #2b2d34 !important;
}

.export-button {
  gap: 20px;

  .headset-icon {
    margin-right: 5px;
  }
}

.support-btn-container {
  font-family: Poppins, sans-serif;
  color: #494b4e;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  line-height: 20px;
}

.support-btn-tooltip {
  background: #fff !important;
  max-width: 370px;
}

.support-btn-popper {
  border-radius: 6px;
}

.support-btn {
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.65rem 1rem;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  box-shadow: none;
  line-height: 1.5;

  &:hover:hover:not(:disabled):not(.active) {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: none;
  }
}

.dashboard-btn {
  margin-top: 0;
  float: right;
  line-height: 1;
  margin-bottom: 27px;
  text-transform: capitalize;
}

a.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.dashboard-btn-disabled {
  cursor: default !important;
  pointer-events: none;
  border-color: #9699a2 !important;
  color: #9699a2 !important;
}

.tabs-panel {
  color: #fff;
}
