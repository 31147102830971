.switching-action-form-control {
  width: 100%;
  min-width: 120px;
  border: none;
  border-bottom: 1px solid rgba(#fff, $alpha: 0.5);

  .MuiSvgIcon-root {
    color: #999;
    font-size: 22px;
    margin-right: -5px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.switching-action-select-menu-root .switching-action-select-menu-paper {
  background-color: #202126 !important;
  color: #fff;
  border-radius: 0 !important;

  ul {
    padding: 0;
  }
}

.switching-action-select-menu-paper .switching-action-menu-item {
  display: flex;
  background-color: inherit !important;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 10px;
  align-items: flex-start;
  padding-bottom: 0;
  margin-bottom: 0;

  span {
    padding-bottom: 6px;
    margin-left: 10px;
    font-family: Poppins, sans-serif;
  }

  .days-menu-item__HR {
    background-color: rgba(0, 0, 0, .5) !important;
    width: 100%;
    margin: 0 auto;
  }

  &:hover {
    background-color: inherit !important;
  }
}

.switching-action-checked,
.switching-action-placeholder {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14.5px;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.switching-action-placeholder {
  opacity: 0.4;
}

.switching-action-form-control .inputCustomWarning {
  color: #f4516c;
  font-weight: 400;
}
