@import "./source";

.overview {
  display: flex;
  flex-direction: column;
  // viewport - header - scrollbar - scaling on small screens
  @include set-responsive-property(calc(100vh - 62px - 20px), calc(100vh - 62px - 20px), calc(100vh - 62px), height);

  padding: 0 10px 10px 10px;
  @media (min-width: 1600px) {
    padding: 0 20px 15px 20px;
  }

  .subheader-overview {
    display: flex;
    align-items: center;
    margin: 0;
    height: 40px;
    @media (min-width: 1534px) {
      height: 60px;
    }
  }
}

.overview-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  @include set-responsive-property(5px, 5px, 0 25px, gap);

  .overview-left-part {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;
    @include set-responsive-property(5px, 5px, 15px, gap);
  }

  .overview-right-part {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    @include set-responsive-property(5px, 5px, 15px 25px, gap);
  }
}

.overview-left-part {
  .vertical-line {
    width: 1px;
    background-color: $dark-light-color;
    height: 75%;
  }

  .production-statistics {
    margin: 0;
    border-radius: 4px;
    background-color: $bg-color;
    display: flex;
    width: 100%;

    .today,
    .last-7-days,
    .year {
      width: 33%;
    }
    .today {
      color: $today-color;
    }

    .last-7-days {
      color: $last-7-days-color;
    }

    .year {
      color: $year-color;
    }

    .title {
      color: $title-light-color;
      font-weight: 300;
      text-align: center;
      @include adaptive-value(10, 25);
      @include set-responsive-property(300, 400, 400, font-weight);
    }

    .value {
      line-height: 1;
      font-weight: 400;
      text-align: center;
      word-spacing: 3px;
      line-height: 1.2;
      @include adaptive-value(20, 70);
      @include set-responsive-property(400, 500, 700, font-weight);

      span {
        font-weight: 300;
        text-align: center;
        word-spacing: 3px;
        @include set-responsive-property(300, 400, 500, font-weight);
        @include adaptive-value(15, 40);
      }
    }
  }

  .plants-data {
    margin: 0;
    align-items: center;
    background-color: $bg-color;
    border-radius: 4px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    .title {
      color: $title-light-color;
      font-weight: 300;
      text-align: center;
      @include adaptive-value(14, 21);
    }

    .value {
      line-height: 2;
      line-height: 1;
      font-weight: 400;
      color: $value-light-color;
      text-align: center;
      word-spacing: 3px;
      @include adaptive-value(35, 70);
      @include set-responsive-property(400, 700, 700, font-weight);
    }
  }
}

.overview-right-part {
  .widgets-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;

    @include set-responsive-property(5px, 5px, 15px, gap);
  }
}
