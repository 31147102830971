$datepicker-main-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$datepicker-main-bg: #2b2d34;
$datepicker-main-font-color: #c6c8d2;
$datepicker-item-hover-bg-color: #9c9c9c;
$datepicker-item-disabled-bg-color: #1f2126;
$datepicker-item-disabled-color: #5a5a5a;
$datepicker-item-active-font-color: #1f2126;
$datepicker-item-active-bg-color: var(--primary-color);

.custom-datepicker-container {
  width: 100%;
}

/* Datepicker */

.custom-antd-datepicker + div {
  position: relative !important;
}

.ant-picker-panel-container {
  background-color: $datepicker-main-bg;
}

.ant-picker-panel,
.ant-picker-panel-focused {
  width: 100%;
  border: none;
}

.ant-picker-date-panel,
.ant-picker-month-panel,
.ant-picker-year-panel,
.ant-picker-decade-panel,
.ant-picker-footer {
  width: 100%;
}

.ant-picker-date-panel {
  .ant-picker-cell {
    min-height: 49px;
    height: 49px;
  }
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-month-panel {
  .ant-picker-cell {
    min-height: 92px;
    height: 92px;
  }
}

.ant-picker-header,
.ant-picker-body {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-content {
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    width: calc(100% / 7);
  }
}

.ant-picker-content {
  th {
    font-family: $datepicker-main-font-family;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.51px;
    color: $datepicker-item-active-bg-color;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 20px solid transparent;
  }
}

.ant-picker-header {
  margin-bottom: 14px;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-prev-btn,
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn {
  min-width: 51px;
  min-height: 55px;
  line-height: 55px;
  margin: 0;
  padding: 0;
  font-family: $datepicker-main-font-family;
  font-size: 23px;
  font-weight: 400;
  color: $datepicker-main-font-color;
  background: transparent;
  border: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $datepicker-item-hover-bg-color;
    color: $datepicker-item-active-font-color;
  }
}

.ant-picker-header-view {
  width: 100%;
  height: 100%;
  color: $datepicker-main-font-color;
  font-size: 23px;
  font-weight: 400;
  line-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker-decade-btn,
.ant-picker-year-btn,
.ant-picker-month-btn {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  color: $datepicker-main-font-color;
  font-family: $datepicker-main-font-family;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0.69px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $datepicker-item-hover-bg-color;
    color: $datepicker-item-active-font-color;
  }
}

.ant-picker-month-btn + .ant-picker-year-btn {
  margin: 0;
}

.ant-picker-cell {
  padding: 0;
  margin: 0;
  font-size: 21px;
  font-weight: 400;
  color: $datepicker-main-font-color;
  border: none;

  &::before {
    height: 48px;
  }

  &:hover:not(.ant-picker-cell-disabled) {
    border-radius: 6px;
    overflow: hidden;
  }

  .ant-picker-cell-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    letter-spacing: 0.63px;
  }
}

.ant-picker-cell-in-view {
  color: $datepicker-main-font-color;
}

.ant-picker-cell-selected:not(.ant-picker-cell-today) {
  border-radius: 6px;
  overflow: hidden;
}

.ant-picker-cell-disabled {
  background-color: $datepicker-item-disabled-bg-color;
  color: $datepicker-item-disabled-color;

  &::before {
    background-color: $datepicker-item-disabled-bg-color;
  }
}

.ant-picker-cell:hover:not(.ant-picker-cell-disabled):not(.ant-picker-cell-today),
.ant-picker-cell-today:hover:not(.ant-picker-cell-disabled):not(.ant-picker-cell-selected) {
  background-color: $datepicker-main-font-color;
  color: $datepicker-item-active-font-color;

  .ant-picker-cell-inner {
    background-color: $datepicker-main-font-color;
    color: $datepicker-item-active-font-color;
  }
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    overflow: hidden;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: $datepicker-item-active-font-color;
  background-color: $datepicker-item-active-bg-color;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  background-color: $datepicker-item-disabled-bg-color;
  color: $datepicker-item-active-bg-color;

  &::before {
    border: none;
  }
}

.ant-picker-cell {
  &:not(.ant-picker-cell-disabled),
  &.ant-picker-cell-today:not(.ant-picker-cell-selected) {
    cursor: pointer;

    .ant-picker-cell-inner {
      cursor: pointer;
    }
  }

  &.ant-picker-cell-disabled,
  &.ant-picker-cell-selected {
    cursor: default;

    .ant-picker-cell-inner {
      cursor: default;
    }
  }
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-month-panel {
  .ant-picker-cell.ant-picker-cell-selected {
    cursor: pointer;

    .ant-picker-cell-inner {
      cursor: pointer;
    }
  }
}

/* Footer */
.ant-picker-footer {
  border: none;

  .ant-picker-today-btn {
    color: $datepicker-item-active-bg-color;
  }
}
