.documentation-wrapper {
  .upload-modal-header {
    align-items: center;
    padding: 0 40px !important;
    margin-bottom: 30px !important;

    .close-btn {
      margin-right: 0;
    }
  }

  .upload-modal-body {
    padding: 0 !important;

    .upload-loader {
      min-height: 120px;
      width: 100%;
    }

    .upload-section {
      .dragger {
        display: flex;
        font-size: 16px;
        margin-bottom: 25px;
        padding-top: 0px !important;

        button {
          background-color: var(--primary-color) !important;
        }

        .files-list {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0;
          color: #fff;
          gap: 5px;
          padding: 0px 40px 0 40px;

          .file-size {
            font-weight: 400;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            padding-right: 21px;
            border-radius: 2px;
            border: 2px solid #40424D;

            .close-btn {
              margin-right: 0px;
              width: 11px;
            }

            button {
              background-color: transparent !important;
            }
          }
        }

        .chooseFile-area {
          margin: 0 40px;
          margin-top: 30px;
          display: flex;
          justify-content: space-between;

          .ant-upload {
            display: flex;
            flex-direction: column;
          }

          .exportBtn {
            font-size: 14px !important;
            height: 38px !important;
            width: 158px !important;
          }

          .upload-all-btn {
            font-size: 14px;
            width: 134px !important;
            background-color: transparent !important;
          }

          .drop-file-text {
            align-items: center;
            color: #fff;
            opacity: 0.5;
            font-size: 12px;
            margin-top: 10px;
            padding-left: 20px;
          }
        }
      }
    }
  }

  .modal-content .modal-header .modal-title {
    margin-left: 0 !important;
  }
}

.ant-upload.ant-upload-btn {
  width: 100%;
}