body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --primary-color: #ffb822;
  --secondary-color: #fff;
  --error-color: #f4516c;
  --success-color: #4ccd37;
  --dark-color: #888;
  --light-dark-color: #959ba2;
}

  input[id=select-all] {
  -moz-appearance: initial;
}

.checkbox-react-table > input[type=checkbox] {
  -moz-appearance: initial;
}
