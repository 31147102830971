@import "../../source";

.overview-pie-charts-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $bg-color;
  border-radius: 4px;
  flex: 1;
  align-items: center;
  padding: 10px;
  @include set-responsive-property(10px, 10px, 20px 45px 30px);

  canvas {
    width: 100% !important;
    max-height: 25vh !important;

    @media (min-width: 1534) {
      max-height: 30vh !important;
    }

    @media (min-width: 1760px) {
      max-height: 35vh !important;
    }
  }

  .title {
    font-weight: 300;
    color: $title-light-color;
    text-align: center;
    @include adaptive-value(20, 27);
    @include set-responsive-property(300, 300, 400, font-weight);
  }

  .status-pie-chart,
  .monitoring-pie-chart {
    height: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1 1 50%;
    @include set-responsive-property(5px, 5px, 10px, gap);
  }

  .additional-info {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(1, 1fr);

    @include set-responsive-property(0, 0px, 10px, gap);

    @media (min-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .empty {
      height: 20px;
    }

    .info-item {
      display: flex;
      align-items: space-between;

      .circle-marker {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        align-self: center;
        margin: 0 7px;
      }

      .value {
        margin-right: 7px;
        color: $value-light-color;
        font-weight: 500;
        line-height: 20px;
        @include adaptive-value(14, 18);
      }

      .name {
        color: rgba($color: #fff, $alpha: 0.5);
        font-weight: 400;
        white-space: nowrap;
        @include adaptive-value(12, 16);
      }

      .installation-ongoing {
        background-color: $status-installation-ongoing-color;
      }

      .connected {
        background-color: $status-connected-color;
      }

      .ok {
        background-color: $status-ok-color;
      }

      .warning {
        background-color: $status-warning-color;
      }

      .error {
        background-color: $status-error-color;
      }

      .not-connected {
        background-color: $status-not-connected-color;
      }
    }
  }
}