.modal-body {
  .switch-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  .tarif-header {
    padding-top: 2px;
  }
}

.modal-footer {
  background-color: #2b2d34 !important;
  border-top: none !important;
  margin-top: 0 !important;
  padding-top: 40px !important;
}

.modal-header {
  h5 {
    margin: 40px 38px 35px 38px !important;
  }
}