.additional-filters__container {
    display: flex;
    
    .installation-finished-filter__container,
    .support-contracts-filter__container {
        padding-left: 20px;

        span {
            color: #8F939B;  
            font-size: 14px;  
        }

        .additional-filters__select option {
            color: #9699a2;
        }

        select {
            height: calc(2.39rem + 4px);
            cursor: pointer;
            margin-left: 5px;
            color: #9699A2;
            border: 1px solid #40424D;
            background-color: #2B2D34;
            width: 150px;
            height: 38px;
            padding-left: 15px;
        }
    }

    .reset-additional-filters-btn__container {
        margin-left: 35px !important;
    }

    .disable-button {
        button {
            border: 1px solid #9699a2 !important;
            color: #9699a2 !important;
        }
    }
}
