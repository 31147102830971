.chartDebugMode {
  color: white;
  padding: 0 10px;
  line-height: 22px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  opacity: 0.7;
}

.chartFlexItem {
  flex: 1 0 300px;
  position: relative;
  overflow: hidden;
}

.chartParent {
  height: 100%;
  width: 100%;
  position: absolute;
}

.highcharts-background {
  fill: #25272d !important;
}

.highcharts-partfill-overlay {
  fill: #2c2e34 !important;
}

.header-det-energy-stat {
  color: #c6c8d2;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: left;
}

.f-line-en {
  margin-top: 27px;
  margin-bottom: 20px;
}

.s-line-en {
  margin-bottom: 27px;
}

.slick-prev {
  left: -18px;
}

.slick-next {
  right: -18px;
}

.det-energy-stat {
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.det-energy-stat-kwh {
  font-size: 24px;
  font-weight: 400;
}

.self-cons {
  color: #459cbe;
}

.cons-bat {
  color: #dadada;
}

.stat-exp {
  color: #4ccd37;
}

.grid-energy {
  color: #f53920;
}

.hot-water {
  color: #f66400;
}
.swiper-wrapper {
  padding-bottom: 10px;
}

.swiper-pagination-bullet {
  margin: 0 15px !important;
  background-color: #c6c8d2 !important;
  &:not(.swiper-pagination-bullet-active):hover {
    background-color: #fff !important;
    opacity: 1;
  }
}

.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

select.form-control.car-charger-input:not([size]):not([multiple]) {
  height: calc(2.39rem + 6px);
  cursor: pointer;
}

.en-stat-data-h {
  padding-top: 12px;
  padding-bottom: 5px;
  margin-top: -10px;
}

.car-charger-img {
  margin-right: 10px;
}

.chart {
  margin-top: 10px;
}

.liveDataStatus {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 24px;
  height: 25px;
}

.liveDataStatus .recIco {
  display: inline-block;
  background: #c10202;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  vertical-align: middle;
}

.topbar-dashb {
  background-color: #212529;
}

.dashboardApp {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
  background-color: #25272d;
}

.progress-energy-percent {
  flex-grow: 0;
  padding-left: 0;
  padding-right: 0;
  opacity: 0.9;
  color: #ffffff;
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.22px;
}

.percent-separator {
  width: 1px;
  background-color: #494d59;
  height: 26px;
}

.percent-separator-small {
  width: 1px;
  background-color: #494d59;
  height: 15px;
}

.date-dashb {
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin-right: 14px;
}

.navig-btn-dashb {
  margin-right: 9px;
  width: 41px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #3e404c;
  background-color: transparent;
  color: #c6c8d2;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    background-color: #999;
  }

  &.btn-today {
    width: auto !important;
    min-width: 78px;
  }

  &.btn-plus-zoom-dashb {
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }

  &.btn-min-zoom-dashb {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
  &.btn-calendar-dashb {
    border: none;
    font-size: 1.4em;
    padding: 0;
    width: auto;
    &:hover {
      background: none;
      color: white;
    }
  }
}

.navig-btn-dashb-act {
  background-color: #3e404c;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.btn-upd-dashb button {
  padding-left: 21px;
  padding-right: 21px;
  color: var(--primary-color);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.27px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.dashb-page-info {
  color: white;
  line-height: 62px;
}

.en-values-container {
  background-color: #2b2d34;
  border-bottom: 1px solid #40424d;
  padding-top: 0;
  padding-bottom: 0;
}

.header-progr-bar-dashb {
  color: #a7a8b2;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
  z-index: 1000;
}

.dashb-abs-header {
  position: absolute;
  margin-top: 8px;
}

.progress-bar {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  line-height: 1.75;

  &.bg-warning {
    background-color: var(--primary-color) !important;
  }

  &.bg-danger {
    background-color: #f53920 !important;
  }

  &.bg-self-cons {
    background-color: #459cbe;
  }

  &.bg-exp {
    background-color: #4ccd37;
  }

  &.bg-battery {
    background-color: #dadada;
  }

  &.bg-empty-battery {
    background-color: #494d59;
  }
}

.progress {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: initial !important;

  .la.la-info-circle {
    font-size: 16px;
    color: #fff;
    line-height: 19px;
  }

  span {
    position: relative;
  }
}

.header-en-value {
  color: #c6c8d2;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 300;
  /* line-height: 40px; */
  text-transform: uppercase;
  letter-spacing: 0.72px;
  margin-bottom: 10px;

  &.car-charger-header {
    margin-bottom: 4px;
  }
}

.en-value {
  font-size: 58px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 1.2px;
}

.en-value-w {
  font-size: 0.5em;
  font-weight: normal;
  margin-left: 2px;
  letter-spacing: 0.6px;
}

.m-form__group .form-control.car-charger-input {
  font-size: 1.1em !important;
}

.use-n {
  color: #3998f9;
}

.fr-gr {
  color: #f53920;
}

.sol-pv {
  color: var(--primary-color);
}

.nav-buttons-dashb {
  margin-top: 10px;
}
