.switching-times__collapse {
  display: flex;
  gap: 18px;
  margin-bottom: 9px;

  .switching-times__collapse-trash {
    position: relative;
    width: 41px;
    height: 41px;
    margin-top: 18px;
    cursor: pointer;

    i {
      color: #959ba2;
      font-size: 34px;
      position: absolute;
      right: 5px;
      left: 2.5;
      top: 1.5px;
      border: 0;
    }

    &:hover {
      border-radius: 100%;
      background-color: rgb(255, 63, 63);

      i {
        color: #fff;
      }
    }
  }
}
