//TODO: delete this file before release https://redmine.indeema.com/issues/44748

$bg-color: #212529;

.overview-wrapper {
  display: flex;
  justify-content: space-around;
  .overview-left-part {
    width: 45%;
  }

  .overview-right-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 53%;

    .circle-charts-row {
      display: flex;
      justify-content: space-between;
    }
  }
}

.vertical-line {
  width: 1px;
  background-color: #707070;
  height: a;
}

.production-statistics {
  display: flex;
  align-items: center;
  height: 17vh;
  background-color: $bg-color;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
}

.production-statistics-wrapper {
  display: flex;
  width: 100%;
  padding: 30px 0;

  .today,
  .last-7-days,
  .year {
    width: 33%;
    padding: 0 10px;
  }

  .today {
    color: #FF9E22;
  }
  .last-7-days {
    color: #00F6E6;
  }
  .year {
    color: #F53920;
  }

  .title {
    font-size: 21px;
    color: #C6C8D2;
    font-weight: 300;
    text-align: center;
  }

  .value {
    font-size: 50px;
    font-weight: 700;
    text-align: center;

    span {
      font-size: 40px;
      font-weight: 400;
    }
  }
}

.plants-data {
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 15px;
  height: 14vh;
  background-color: $bg-color;
  width: 100%;
}
.plants-data-wrapper {
  padding: 15px 60px;
  display: flex;
  width: 100%;
  justify-content: space-around;

  .title {
    font-size: 20px;
    color: #C6C8D2;
    font-weight: 300;
    text-align: center;
  }

  .value {
    font-size: 70px;
    line-height: 70px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    word-spacing: 3px;
  }
}

.widgets-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
  gap: 15px;

  .widget-row {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1600px) {
  .production-statistics-wrapper {
    
    .title {
      font-size: 21px;
    }
  
    .value {
      font-size: 40px;
  
      span {
        font-size: 30px;
      }
    }
  }

  .plants-data-wrapper {
    padding: 10px 60px;

    .value {
      font-size: 55px;
      line-height: 55px;
    }
  }
}

@media (max-width: 1366px) {
  .overview-wrapper {
    gap: 15px;

    .overview-right-part {
      .circle-charts-row {
        gap: 15px;
      }
    }
  }

  .production-statistics-wrapper {
    .title {
      font-size: 16px;
    }

    .value {
      font-size: 35px;
    }
  }

  .plants-data-wrapper {
    padding: 7px 40px;
  
    .title {
      font-size: 16px;
    }
  
    .value {
      font-size: 45px;
      line-height: 45px;
    }
  }

  .widgets-wrapper {
    margin-bottom: 15px;
  }
}

@media (max-width: 820px) {
  .production-statistics-wrapper {
  
    .today {
      .title {
        font-size: 14px;
      }

      .value {
        font-size: 20px;
        span {
          font-size: 15px;
        }
      }
    }
    .last-7-days {
      .title {
        font-size: 14px;
      }
  
      .value {
        font-size: 20px;
        span {
          font-size: 15px;
        }
      }
    }
    .year {
      .title {
        font-size: 14px;
      }
  
      .value {
        font-size: 20px;
        span {
          font-size: 15px;
        }
      }
    }
  };

  .plants-data-wrapper {
    .title {
      font-size: 14px;
    }

    .value {
      font-size: 40px;
    }
  }

  .additional-info {
    display: flex;
    flex-direction: column;
  }

  .circle-chart-wrapper .chart-info .chart-info-row {
    .value, .label {
      font-size: 13px !important;
    }
  }

  .widget-content-row {
    .widget-label {
      font-size: 12px !important;
    }

    .value {
      span {
        font-size: 12px !important;
      }
    }
  }

  .widget-name {
    font-size: 13px !important;
  }
}