.device-id-rtu {
  position: relative;
  display: flex;
  flex-direction: column;
}

.container-of-button-and-tooltip {
  position: absolute;
  top: 10px;
  right: 0;
  justify-content: center;
  display: flex;
}

.tooltip-set-id-RTU-container {
  font-size: 20px;
  display: inline;
  margin-left: 10px;
  margin-top: 2px;
}

.device-id-rtu .inputField:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  color: #fff !important;
  -webkit-background-clip: text !important;
  -webkit-box-shadow: 0 0 0px 0px transparent !important;
  box-shadow: 0 0 0px 0px transparent !important;
  caret-color: #fff;
  font-weight: 100 !important;
  font-family: Poppins, sans-serif !important;
}

.device-id-RTU-field {
  width: 100%;
}

.inputRoot input[name="data.Device_ID_RTU"] {
  padding-right: 110px !important;
}

.container-of-button-and-tooltip .button-on-click-set-Id {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}