.invited-users-wrapper {
  .invited-users-header-container {
    margin: 50px 0 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invited-users-header-title {
    font-size: 18px;
    color: #fff;
    margin: 0;
  }
}