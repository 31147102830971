.add-gateway-preloader-container,
.installedsm-delete-prealoder-container {
  height: 185px;
  display: flex;
  align-items: center;
}

.search-field {
  max-width: 300px !important;
}

.version-field{
  max-width: 250px !important;
}

.modal-body-gateway {
  padding-top: 25px !important;
}

.name-or-smid-note {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #ffffff;
  border: 1px solid #5e6774;
  background-color: #2b2d34;
}

.confirmation-field {
  margin: 0 auto;
  padding: 0 50px;
}

.confirmation-field>.customTextField {
  width: 100%;
}

.confirmation-field>.customTextField>label {
  padding-left: 10px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.confirmation-field>.customTextField>.inputRoot>input {
  border: 1px solid #5e6774;
  padding-left: 10px;
}

.confirmation-field>.customTextField>.inputRoot>input:focus {
  border: 1px solid #f4b530;
}
.multi-select-wrapper{
  flex:1;
}
.multi-select {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin: 15px 15px !important;
  color: #ffffff7f;
  margin-top: 15px;
}

.confirm-deleting-text {
  font-size: 16px !important;
}

.installed-table>.ReactTable.m-datatable__table {
  padding-bottom: 60px;
}

.installed-table>.m_datatable.m-datatable.m-datatable--default.m-datatable--brand.m-datatable--loaded>.m-datatable__pager.m-datatable--paging-loaded.clearfix {
  margin-top: 0;
}

.filter-element {
  margin-bottom: -10px !important;
  max-height: 100px;
}

.rebootReqBtn {
  min-width: max-content;
}

.rebootReqBtn.English {
  width: 150px !important;
}

.rebootReqBtn.French {
  width: 150px !important;
}

.rebootReqBtn.Deutsch {
  width: 150px !important;
}

.rebootReqBtn.Italian {
  width: 130px !important;
}

.rebootReqBtn.Danish {
    width: 140px !important;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden;
}