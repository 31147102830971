$primary: var(--primary-color);
$wrapper-color: #40424d;
$bg-color: #25282e;

.user-settings {
  background-color: $bg-color;
  .MuiTabs-root {
    color: #fff;
    font-family: Poppins, sans-serif;
  }
  .MuiButtonBase-root {
    opacity: 1;
    font-family: Poppins, sans-serif;
    font-size: 16px;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid $wrapper-color;
  }

  .Mui-selected {
    color: $primary;
  }

  .MuiTabs-indicator {
    background-color: $primary;
    height: 2px;
  }
  .tab-wrapper {
    border: 1px solid #40424d;
    border-radius: 7px;
  }
}
