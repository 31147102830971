.flagSelectClosed ul {
  color: #000;
  font-size: 13px !important;
}

.flagSelectClosed ul::-webkit-scrollbar {
  width: 0.4em;
}

.flagSelectClosed ul::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  outline: var(--primary-color);
}

.country-btn {
  padding: 0 !important;
  color: #c4c7cb !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  border: none !important;
  background: transparent !important;
}

.country-btn>span {
  padding: 0;
  margin: 2px 0;
  width: auto;
}

.country-btn>span span:last-child {
  color: #fff;
  font-size: 15px;
}

.country-btn:after {
  display: inline-block;
  border-top: 5px solid #999 !important;
  margin-right: 7px;
}

.country-btn_disabled:after {
  opacity: 0.35 !important;
}

.country-btn[aria-expanded="true"]:after {
  border-bottom: 5px solid #999 !important;
  border-top: none !important;
}