#monitoring_expander_filter-reg {
    margin-left: 5px;
    color: #9699A2;
    border-color: #40424D;
    background-color: #25272D;
    width: 250px;
    height: 38px;
    padding: .65rem 1rem;
}

#monitoring_expander_filter-reg option {
    color: white;
}

#pvisntaller_filter-reg, #assign_filter-reg, #status_filter-reg {
    color: #9699A2;
    border: 0;
}

.custom-search-container {
    width: 230px !important;
    margin: 0 !important;
}

.customSearch::placeholder {
    font-size: 11px;
}

.searchBox {
    margin-top: 0 !important;
}

.apply-monitoring-container {
    background-color: #25272D;
    color: #8F939B;
    padding: 0 15px 15px;
    align-items: end;
}

.main-filter-monitoring-container {
    color: #8F939B;
}

.btn-monitoring {
    background-color: var(--primary-color);
    color: white;
    width: 110px;
    margin-left: 5px;
    padding: 10px 5px !important;
}

.monitoring-tool-tip {
    font-size: 16px;
}

.nav-monitoring {
    height: 35px;
    margin-top: -15px;
    padding-left: 0;
}

.monitoring-highlight .rt-th.checkbox-react-table-all:nth-child(2) {
    width: 50px !important;
    max-width: 50px !important;
}

.monitoring-highlight .rt-td.checkbox-react-table:nth-child(2) {
    width: 50px !important;
    max-width: 50px !important;
}

.input-note {
    width: 430px;
    height: 100px;
    margin: 20px;
    padding: 7px 15px;
    color: #FFFFFF;
    border: 1px solid #40424D;
    background-color: #2b2d34;
}

#pvisntaller_filter-reg_input:focus, #assign_filter-reg_input:focus, #status_filter-reg_input:focus {
    border: none;
}

.table-row {
    background-color: #2D3037;
    padding-top: 20px;
    padding-bottom: 20px;
}

.search-field {
    margin: 0;
}

div .optionListContainer {
    z-index: 20;
}

ul.optionContainer li {
    color: white;
}

li input[type="checkbox"] {
    background-color: #40424D;
}

ul.optionContainer li:first-child {
    background-color: #40424D;
}

ul.optionContainer li:hover {
    background-color: #25272D;
}

.note-edit-prealoder-container {
    height: 236px;
    display: flex;
    align-items: center;
}

.number-column {
    margin-left: 12px;
    width: 15px;
}

.device-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 41%;
    margin-left: 12px;
}

.signal-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16%;
    max-width: 16%;
}

.error-date-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 30%;
}

.error-status-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 13%;
    max-width: 13%;
}

.assign-container {
    width: 30%;
    padding-left: 1%
}

.priority-container {
    width: 30%
}

.status-container {
    width: 30%
}

.monitoring-top-bar i.la-info-circle {
    /* position: absolute;
    top: 18%;
    left: 87%; */
    margin-right: 30px;
}

.button-container {
    max-width: 10%;
    margin-left: 20px;
}

.table-row-details-td.undertable-note {
    overflow-wrap: break-word;
}

.monitoring-switch {
    margin-right: 15px;
}

.switch-container,
.custom-gap {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.resetBtn {
    display: flex;
    align-items: flex-end;
}

.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-disabled {
    border: 1px solid #9699a2 !important;
    color: #9699a2 !important;
}

.monitoringSignalColumn {
    margin-left: 15px;
}

.monitoring-table {
    position: relative;
}
.monitoring-table > .ReactTable.monitoring-highlight.m-datatable__table {
    padding-bottom: 90px;
}
.monitoring-table > .m_datatable.m-datatable.m-datatable--default.m-datatable--brand.m-datatable--loaded {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.monitoring-table > .ReactTable.monitoring-highlight.m-datatable__table > .rt-table > .rt-tbody,
.monitoring-table > .ReactTable.monitoring-highlight.m-datatable__table > .rt-table > .rt-thead {
    min-width: 1375px !important;
}

@media only screen and (max-width: 1100px) {
    .assign-container {
        width: 70%;
        padding-left: 1%
    }

    .priority-container {
        width: 70%
    }

    .status-container {
        width: 70%
    }

    .button-container {
      max-width: 30%;
  }
}
