.switching-times__container {
  display: flex;
  flex-direction: column;

  .switching-times__collapse-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .switching-times__empty-list {
      text-align: center;
      color: #fff;
      padding: 10px;
    }
  }
}

.switching-times__add-new-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 45px;
  height: 45px;
  background-color: #F0AD23;
  border-radius: 100%;
  cursor: pointer;

  img {
    height: 35px;
  }
}

