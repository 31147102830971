.switch-tooltip + .customTextField
    overflow: hidden
    input.inputField
        padding-right: 26px

.customTextField
    .inputLabel
        white-space: nowrap
        text-overflow: ellipsis
        max-width: 360px
        overflow: visible
    .inputLabel[data-shrink="true"]
        max-width: 510px
        width: 100%
    label
        line-height: 1.5
    label[data-shrink="false"]
        color: rgba(255, 255, 255, .8)

    label[data-shrink="true"]
        color: rgb(244, 181, 48) !important

    input.inputField
        font-size: 14px !important
        line-height: 18px
        padding: 6px 0 7px
        height: 1.1876em

    .inputRoot.Mui-disabled input,
    .inputRoot.Mui-disabled input::placeholder
        color: #fff
        -webkit-text-fill-color: #fff

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active
        -webkit-animation-name: autofill
        -webkit-animation-fill-mode: both

    .inputRoot.MuiInput-disabled-18:before,
    .inputRoot.MuiInput-disabled-18:after
        border-bottom: none !important

    .inputRoot:before
        border-bottom: 1px solid #5e6774 !important
    .inputRoot:after
        border-bottom: 1px solid rgb(244, 181, 48) !important

@-webkit-keyframes autofill
    to
    color: #fff
    background: transparent

.inputCustomWarning
    color: #f4516c
    font-weight: 400
    padding-left: 1.6rem
    margin: 8px 0 0

.search-ip-btn
    line-height: 0.3 !important
