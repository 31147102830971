.tokenStringField {
  overflow: visible !important;
}

.generateTokenBtn {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  position: absolute;
  right: 0;
  top: 25px;
  color: #F0AD23;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  margin-right: 5px;
  padding: 0;
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    filter: brightness(0.5);

    &:hover {
      text-decoration: none;
    }
  }
}
